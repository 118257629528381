/* Assignment 4 - David Morgan */

/* 	******* CHECKERBOARD FRAMEWORK ******* 
	This is a simple 6-column CSS framework with a 'checkerboard' flair.  It includes
	a standardized color schmeme, grid layout, media (screensize) breakpoints, and is completely
	mobile-compatible.
*/

@import "variables"; //Copied from my Assignment #2 - all code is my own


/* *** 6-Column Grid System *** */


/* *** Media Breakpoints *** */


/* *** Color Scheme *** */


/* *** Typography *** */


/* *** Navigation *** */
.navig {
	background-color: $color-two;
	padding-top: .5em;
	padding-bottom: .5em;
	width: 100%;
}

/* *** Style Elements *** */
h1, h2, h3 {
	font-family: 'Acme', sans-serif;
	color: $color-base;
}
header {
	background-color: $color-one;
	color: white;
	width: 100%;
}