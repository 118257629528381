// color variables 
// color scheme built partly off the work of Natalya Shelburne's Sass formulas

$color-base: hsl(330, 30%, 60%);
$color-one: mix($color-base, complement($color-base), 15%);
$color-two: complement($color-one);
$color-background: rgba($color-one, .2);
$color-accent: lighten($color-two, 20%);
$color-titles: yellow;  //default to show errors in compiling

//measurements
$border-radius-small: 0.33em;
$border-radius-medium: 0.66em;
$border-radius-large: 1em;
$standard-space: 1em;

//css variables
:root {
	--base-font-size: 1em;
	--standard-padding: .5em;
	--standard-margin: .5em; 
}